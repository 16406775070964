import styles from './style.module.scss';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from 'components/ui/dialog';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from '../../../../services/axios';
import Loader from '../../../atoms/Loader';
import ArrowDown from '../../../../public/images/arrow-down.svg';
import ArrowRight from '../../../../public/images/arrow-right.svg';
import IconEdit from '../../../../public/images/edit-icon.svg';
import { Button } from 'components/ui/button';
import { useState, useEffect, Fragment } from 'react';
import NewReleaseModal from '../../../../components/molecules/Modal/NewReleaseForm';
import { useAuthContext } from 'hooks/useContexts';

export default function ReleaseModal({
  changeSetOpen,
  isOpen,
  numberOfNotif,
  refetch,
  setRefetch
}) {
  const { t } = useTranslation();

  const feature = {
    feature: '',
    roles: []
  };

  const category = {
    category: '',
    features: [feature]
  };

  const initialFormState = {
    date: new Date(),
    categories: [category]
  };

  const [isActive, setIsActive] = useState(false);
  const [openRelease, setOpenRelease] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState(initialFormState);
  const [newRelease, setNewRelease] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMoreCount, setViewMoreCount] = useState(3);

  const { user } = useAuthContext();
  const role = user?.roles?.rolesBroadcomContact[0]?.roles?.name;

  const permissionRelease = user?.email === 'joao.rosa@broadcom.com';
  const isSiteAdmin = role === 'Expert Advantage Site Admin';

  useEffect(() => {
    if (!isNaN(numberOfNotif) && numberOfNotif === 0) setIsActive(true);
  }, [numberOfNotif]);

  const { data: releases } = useQuery({
    queryKey: ['releases', refetch],
    queryFn: async () => {
      setIsLoading(true);
      const res = await getAPIClient().get(`/releases`);

      const tempReleases = {};

      res.data.map((r) => {
        let releaseDate = new Date(r.releaseDate)
          .toString()
          .split(' ')
          .slice(0, 4)
          .join(' ');
        if (!tempReleases[releaseDate]) {
          tempReleases[releaseDate] = [];
        }
        tempReleases[releaseDate].push({ ...r, date: new Date(r.releaseDate) });
      });

      const releases = Object.keys(tempReleases).map((relDate) => {
        let features = tempReleases[relDate];
        features = features.reduce((feat, item) => {
          const { category } = item;

          if (!feat[category]) {
            feat[category] = {
              category,
              features: []
            };
          }
          feat[category].features.push(item);

          return feat;
        }, {});
        features = Object.values(features);

        return {
          relDate,
          date: tempReleases[relDate][0].date,
          categories: features
        };
      });

      setIsLoading(false);
      return releases;
    },
    refetchOnWindowFocus: true
  });

  const handleViewMore = () => {
    setViewMoreCount((prev) => prev + 3);
  };

  return isLoading && isOpen ? (
    <Loader />
  ) : (
    <>
      <Dialog open={isOpen} onOpenChange={changeSetOpen}>
        <DialogContent className="md:p-0 gap-0">
          <DialogHeader className="bg-[#2360A0] rounded-t-[23px]">
            <DialogTitle className="text-[#fff] text-[20px] px-8 pt-8 pb-4">
              {t('new_and_improved_on_insights')}
            </DialogTitle>
          </DialogHeader>
          {releases && (
            <div className={styles.content}>
              {permissionRelease && (
                <Button
                  icon="add"
                  onClick={() => {
                    setSelectedRelease(initialFormState);
                    setOpenRelease(true);
                    setNewRelease(true);
                  }}
                >
                  {t('release')}
                </Button>
              )}
              <div className={styles.new_releases}>
                {
                  <>
                    <div className={styles.releases}>
                      {releases.map((rel, index) => (
                        <Fragment key={index}>
                          {index === numberOfNotif && (
                            <div
                              className={styles.last_updates}
                              onClick={() => setIsActive(!isActive)}
                            >
                              <p>{t('last_updates')}</p>
                              {isActive ? <ArrowDown /> : <ArrowRight />}
                            </div>
                          )}
                          {(isActive || index < numberOfNotif) &&
                            index < viewMoreCount + numberOfNotif &&
                            rel.categories.some(
                              (f) =>
                                f.features.some((feat) => feat.visibleToAll) ||
                                f.features.some((feat) =>
                                  feat.roles?.map((r) => r.name)?.includes(role)
                                ) ||
                                isSiteAdmin
                            ) && (
                              <div className={styles.release}>
                                <span
                                  className={
                                    index >= numberOfNotif
                                      ? styles.last_releases
                                      : ''
                                  }
                                >
                                  {`${t(
                                    rel.relDate.split(' ')[0].toLowerCase()
                                  )} ${t(
                                    rel.relDate.split(' ')[1].toLowerCase()
                                  )} ${rel.relDate.split(' ')[2]} ${
                                    rel.relDate.split(' ')[3]
                                  }`}
                                  {permissionRelease && index >= numberOfNotif && (
                                    <IconEdit
                                      className={'ml-2 cursor-pointer'}
                                      onClick={() => {
                                        setSelectedRelease(
                                          releases.find(
                                            (r) => r.relDate === rel.relDate
                                          )
                                        );
                                        setNewRelease(false);
                                        setOpenRelease(true);
                                      }}
                                    />
                                  )}
                                </span>
                                {rel.categories.map((feat, index) => (
                                  <Fragment key={`featCat_${index}`}>
                                    {feat.features.some(
                                      (f) =>
                                        f?.roles?.length === 0 ||
                                        f?.roles
                                          ?.map((obj) => obj.name)
                                          .includes(role) ||
                                        isSiteAdmin
                                    ) && <p>{feat.category}</p>}

                                    <ul className={'mt-2 pl-5'}>
                                      {feat.features.map(
                                        (f, index) =>
                                          (f?.roles?.length === 0 ||
                                            f?.roles
                                              .map((obj) => obj.name)
                                              .includes(role) ||
                                            isSiteAdmin) && (
                                            <li key={`li_${index}`}>
                                              {f.feature}
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  </Fragment>
                                ))}
                              </div>
                            )}
                        </Fragment>
                      ))}
                    </div>
                    {isActive &&
                      viewMoreCount < releases.length - numberOfNotif && (
                        <div>
                          <Button
                            className="mt-2 pt-2 font-normal w-full !relative !right-0"
                            onClick={handleViewMore}
                          >
                            {t('view_more')}
                          </Button>
                        </div>
                      )}
                  </>
                }
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {openRelease && (
        <NewReleaseModal
          setOpen={setOpenRelease}
          open={openRelease}
          changeSetOpen={changeSetOpen}
          selectedRelease={selectedRelease}
          setSelectedRelease={setSelectedRelease}
          initialFormState={initialFormState}
          feature={feature}
          category={category}
          newRelease={newRelease}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
}
